import { Capacitor } from '@capacitor/core'

import { useDeliveryType } from '@/hooks/app'
import { useMember } from '@/hooks/user'
import { useMerchant } from '@/hooks/merchant'
import { useSelector } from '@/redux'
import PaymentMethod from '@/constants/paymentMethod'
import config from '@/config'
import paymentGateway from '@/constants/paymentGateway'

function canMakeApplePay () {
  if (!window.location.href.includes('https')) {
    return false
  }
  if (!window.ApplePaySession) {
    return false
  }
  if (!window.ApplePaySession.canMakePayments(config.applePay2c2p.merchantId)) {
    return false
  }
  return true
}

export default function useAcceptedPaymentMethods () {
  const member = useMember()
  const merchant = useMerchant()
  const { isTable } = useDeliveryType()
  const creditCardGateway = useSelector(state => state.app.paymentGateway.creditcard)
  const platform = Capacitor.getPlatform()

  const acceptedPaymentMethods = []

  if (!merchant?.setting) return []

  if (config.env !== 'prod') {
    // 非 production 環境顯示 bypass payment
    acceptedPaymentMethods.push(PaymentMethod.BYPASS)
  }

  if (isTable ? merchant.setting.enableDineInPayMe : merchant.setting.enablePayMe) {
    acceptedPaymentMethods.push(PaymentMethod.PAY_ME)
  }

  acceptedPaymentMethods.push(PaymentMethod.CREDIT_CARD)

  if (creditCardGateway === paymentGateway.PAYMENT_GATEWAY_2C2P) {
    // 2c2p 信用卡支援 recent 和 apple pay
    if (member?.payments?.length) {
      acceptedPaymentMethods.push(PaymentMethod.RECENT)
    }
    // FIXME: 2c2p applepay他們設定問題. 我們暫時隱藏
    // if (canMakeApplePay()) {
    //   acceptedPaymentMethods.push(PaymentMethod.APPLE_PAY)
    // }
  }

  if (isTable ? merchant.setting.enableDineInOctopusOnline : merchant.setting.enableOctopusOnline) {
    acceptedPaymentMethods.push(PaymentMethod.OCTOPUS)
  }
  const list = [
    '48f2e374-f9f2-4394-8806-e35c3c10334e',
    '73b2503e-3627-4f1a-8f68-f228bedeb211',
    'dfb8d0fe-3b72-4d1a-9073-72e1d1642443',
    'dc856043-579d-4d8f-bc29-68722bc303f1',
    '350414e9-ac6d-4cd1-b8eb-a26294429e87',
    '917a7c97-346a-4f5d-ab64-dcf313237da5',
    'c23c1cdf-2127-4b82-9dfb-cbadf8b568e4',
    '6073d228-f0e1-469d-b706-76945e807615',
    'a1feba29-bd01-4569-af51-fdf88799088b',
    '35d15afc-741d-45c0-b157-ab5d93c51bec',
    '542c0e45-43f2-4269-8c5a-c2519f3b267a',
    '44a11ac3-3f81-4308-ba2f-e173162b46a1',
    '6be47720-3ab3-403e-b37f-7455fc4692fd',
    '429e35b2-9311-4b4c-9903-bed5ad74fc6a',
    'a0fc1acb-54c0-4d21-b3ba-930ac50a2bec',
  ]
  if (isTable ? merchant.setting.enableDineInWechatPay && list.includes(merchant.id) : merchant.setting.enableWechatPay) {
    acceptedPaymentMethods.push(PaymentMethod.WECHAT_PAY)
  }
  if (isTable ? merchant.setting.enableDineInAliPay && list.includes(merchant.id) : merchant.setting.enableAliPay) {
    acceptedPaymentMethods.push(PaymentMethod.ALI_PAY)
  }
  if (platform !== 'web') {
    if (isTable ? merchant.setting.enableDineInFps : merchant.setting.enableFps) {
      acceptedPaymentMethods.push(PaymentMethod.FPS)
    }
  }

  return acceptedPaymentMethods
}
